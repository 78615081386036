import * as types from '../../constants/services';

const initialState = [undefined];

function ServiceReducer(state = initialState, action) {

	switch (action.type) {
		case types.LOAD_SERVICES:
			return action.services;
		case types.ADD_SERVICE:
			return [
				...state,
				action.services
			];	
		default:
			return state
	}
}

export default ServiceReducer;