/* eslint-disable react/prop-types */
import React from 'react';
import Page from '../../templates/Page';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportActions from '../../../redux/actions/ReportsActions';
import './ReportsPage.scss';
import ReportsTable from '../../organisms/ReportsTable';

export function ReportsPage() {

	return (
		<Page>
			<Row
				className="reports-page"
				data-testid="reports-page"
			>
				<ReportsTable />
			</Row>

		</Page>

	);
}

const mapDispatchToProps = dispatch => bindActionCreators(reportActions, dispatch);

const mapStateToProps = state => ({
	reports: state.reports.reports,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
