import React from 'react';
import { Form, Input, Select } from 'antd';
import { EditableContext } from '../../molecules/BlacklistTable/BlacklistTable';

function EditableCell({
	children,
	editing,
	dataIndex,
	value,
	inputType,
	services,
	record,
	required,
	...rest
}){

	const isEditing = record ? record.newRow ? record.newRow : editing ? editing : false: false;
	
	return (
		<td {...rest} data-testid="editable-cell">

			{isEditing  ? (
				<EditableContext.Consumer>
					{form => (
						<Form.Item hasFeedback>
							{form.getFieldDecorator(dataIndex,{
								initialValue: inputType === 'text'? value: value._service ? value._service.id : null,
								rules: [{
									required,
									message: ''
								}]
							})(
								inputType === 'text' ? (
									<Input 
										style={{ width: '100%'}}
										data-testid={`editable-cell-${dataIndex}`}
									/>
								):(
									<Select
										allowClear
										style={{ width: '100%' }}
										placeholder="Select a service"
										id={`editable-cell-${dataIndex}`}
									>
										{services.map((service, i) => {
											return (
												<Select.Option key={i} value={service.id}>{service.name}</Select.Option>
											);
										})}
									</Select>
								)
							)}
                    
						</Form.Item>
					)}
               
				</EditableContext.Consumer>
           
			): children}
		</td>
	);
}

export default EditableCell;
