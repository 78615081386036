import React, { useState } from 'react';
import { Modal, Upload, Row, Col, Button } from 'antd';
import Reader from '../../../services/FileReader';

const { Dragger } = Upload;

function CertificatesDialog({ open, close, onSetPrivate, onSetCertificate}) {

	const [_private, setPrivate] = useState();
	const [_certificate, setCertificate] = useState();

	return (
		<Modal
			visible={open} 
			onCancel={close}
			footer={(
				<Button
					disabled={!(_certificate && _private)}
					type="primary"
					onClick={() => {
						onSetCertificate(_certificate.content);
						onSetPrivate(_private.content);
						close();
					}}
				>
                    Save
				</Button>
			)}
			title="Client Certificate Authentication"
		>
			<Row>
				<Col md={12}>
					<h4>Private</h4>
					<Dragger 
						customRequest={()=> null}
						accept=".pem"
						showUploadList={false}
						onChange={({ file }) => {
							const { originFileObj } = file;
							Reader({
								files:[ originFileObj ]
							}).then( content => {
								setPrivate({
									...file,
									content
								});
							});
						}}
					>
						{_private? (
							<p>
								{_private.name}
							</p>
						):(
							<p>Click to upload your file</p>
						)}
					</Dragger>
					{_private && (
						<Button
							style={{float: 'right', marginTop:5}}
							icon="delete"
							size="small"
							onClick={() => {
								setPrivate(undefined);
								onSetPrivate(undefined);
							}}
						>
                            Delete
						</Button>
					)}
				</Col>

				<Col md={12}>
					<h4>Certificate</h4>
					<Dragger
						customRequest={()=> null}
						accept=".pem"
						showUploadList={false}
						onChange={({ file }) => {
							const { originFileObj } = file;

							Reader({
								files:[ originFileObj ]
							}).then( content => {
								setCertificate({
									...file,
									content
								});
							});
						}}
					>
						{_certificate? (
							<p>
								{_certificate.name}
							</p>
						):(
							<p>Click to upload your file</p>
						)}
                        
					</Dragger>
					{_certificate && (
						<Button
							style={{float: 'right', marginTop:5}}
							icon="delete"
							size="small"
							onClick={() =>{
								setCertificate(undefined);
								onSetCertificate(undefined);
							}}
						>
                            Delete
						</Button>
					)}
				</Col>
			</Row>

		</Modal>
	);
}

export default CertificatesDialog;