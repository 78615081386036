import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as ServiceActions from '../../../redux/actions/ServiceActions';
import Dropzone from '../../molecules/Dropzone';
import DropzoneList from '../../molecules/DropzoneList/DropzoneList';
import { Button, Icon, notification } from 'antd';
import { bindActionCreators } from 'redux';
import './LoadServices.scss';

function LoadServices({ loadServices }) {

	const [files, setFiles] = useState([]);
	const [saveLoading, setSaveLoading] = useState(false);

	return (
		<div className="load-services" data-testid="load-services">
			<Dropzone 
				onReadFile={(jsonFile)=>{
					setFiles((oldFiles) => [...oldFiles, jsonFile] );
				}}
				disabled={!!files.length}
			/>
			<br />

			<DropzoneList 
				items={files}
				setFiles={setFiles}
				onSetCertificates={(name, type, content) => {
					setFiles(files.map(file => {
						if(file.name === name){
							file[type] = content;
						}
						return file;
					}));
				}}
				onRemove={(name)=>{
					setFiles(files.filter(file => file.name !== name));
				}} 
			/>
            
			<div className="load-services__controls">
				<Button 
					type="primary"
					disabled={ files.length === 0 }
					data-testid="load-services-save"
					loading={saveLoading}
					onClick={()=>{
						setSaveLoading(true);
						loadServices(files).then((m) => {
							let _notification = [];
							if(typeof m === 'boolean') _notification = ['Service loaded!','The service was loaded successfully.', 'smile'];
							else if(typeof m === 'string') _notification = ['Warning!',m, 'warning'];
							setFiles([]);
							setSaveLoading(false);

							const notificationData = {
								message: _notification[0],
								description: _notification[1],
								icon: <Icon type={_notification[2]} style={{ color: '#108ee9' }} />,
								config: {
									placement: 'bottomRight',
									bottom: 10
								}
							};
							notification.open(notificationData);
						});
					}}
				>
                    Save
				</Button>
			</div>
		</div>
	);
}

LoadServices.propTypes = {
	loadServices: PropTypes.func
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(ServiceActions, dispatch);

export default connect(null, mapDispatchToProps)(LoadServices);