import * as types from '../../constants/vinrouter';
import Api from '../../../services/Api/Api';
import Axios from 'axios';

export const loadOrders = () => {
	return (dispatch) => {
		return Api.get('/order').then(({ data }) => {
			dispatch({
				type: types.LOAD_ORDERS,
				orders: data.orders,
			});
		});
	};
};

export const runOrder = (params, uuid) => {
	const qs = Object.entries(params)
		.map((v) => {
			if (v[1] && v[0] !== 'orders') {
				return `${v[0]}=${encodeURIComponent(v[1])}`;
			} else {
				return null;
			}
		})
		.filter((v) => v !== null)
		.join('&');

	const _orders = params.orders.map((order) =>
		Api.get(`/admin_vin_router?order_id=${order}&${qs}&uuid=${uuid}`)
	);

	return (dispatch) => {
		 return Axios.all(_orders).then(
			Axios.spread((firstOrder, secondOrder) => {
				if (firstOrder) {
					firstOrder.data.order = params.orders[0];
				}

				if (secondOrder) {
					secondOrder.data.order = params.orders[1];
				}
        
				const result =
	        _orders.length === 2
	        	? [firstOrder.data, secondOrder.data]
	        	: [firstOrder.data];

				dispatch({
					type: types.SET_RESULT,
					result: result,
				});
        
			})
		);
	};
};
