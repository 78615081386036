import * as types from '../../constants/reports';
import Api from '../../../services/Api/Api';

export const getReports = () => {
	return dispatch => {
		
		dispatch({
			type: types.SET_REPORTS,
			reports: undefined
		});

		Api.get('/reports').then(({ data }) => {
			console.log('data reduce action: ', data);
			dispatch({
				type: types.SET_REPORTS,
				reports: data.item
			});
		});
	};
};