import Cognito from './Cognito';

const Session = (location, history, options = {}) => {

	if(!location || !history) return;

	options = {
		routes: {
			auth: '/login',
			home: '/'
		},
		useCodeGrantType: true,
		...options
	}; 

	const { search, pathname } = location;
	const { push } = history;
	const { routes } = options;

	Cognito.userhandler = {
		onSuccess: (e) => {
			if(pathname.name !== routes.home){
				push(routes.home);
			}
		},
		onFailure: () => Cognito.signOut()
	};

	if(options.useCodeGrantType){
		Cognito.useCodeGrantFlow();
	}
	/**
     * Check if user is signed in 
     */
	if(!Cognito.isUserSignedIn()){ // Isn't signed in
		/**
         * Now we check which step of authentication is the user
         */
		if(search){ //Check if there is any code on URL
			/**
             * If there is, so we will parse that
             */
			Cognito.parseCognitoWebResponse(window.location.href);

			return {
				session: Cognito.getCachedSession(),
				user: Cognito.getCachedSession().getIdToken().decodePayload(),
				signOut: ()=> Cognito.signOut()
			};

		}else{
			/**
             * If there isn't, so the user must sign in
             */
			if(pathname !== routes.auth){ // If user isn't in auth route
				/**
                 * Now, we get session and refresh token
                 */
            
				const refreshToken = Cognito.getCachedSession().refreshToken.refreshToken;
                                    
				if(refreshToken){
					Cognito.refreshSession(refreshToken);
					return {
						user: Cognito.getCachedSession().getIdToken().decodePayload(),
						signOut: () => Cognito.signOut(),
					};
				}else {
					push(routes.auth);
				}

			}

			return {
				signIn: () => Cognito.getSession()
			};

		}

	}else { // If is signed
		if(pathname !== routes.home && pathname === routes.auth ){
			push(routes.home);
		}

		return {
			signOut: () => Cognito.signOut(),
			user: Cognito.getCachedSession().getIdToken().decodePayload(),
			session: Cognito.getCachedSession(),
		};
	}
};

export default Session;
