import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import JsonReader from '../../../services/JsonReader';
import './Dropzone.scss';

function Dropzone({ onReadFile, disabled }) {

	const onDrop = useCallback( async ( files )=>{
		const jsonReader = await JsonReader({files});
		onReadFile({
			name: files[0].name,
			data: jsonReader
		});
	}, [ onReadFile ]);

	const { 
		getRootProps, 
		getInputProps, 
		isDragActive 
	} = useDropzone({
		onDrop,
		accept: 'application/json' ,
		multiple: false,
		disabled
	});

	return !disabled && (
		<div {...getRootProps()} className="dropzone" data-testid="dropzone">
			<div className="dropzone-internal">
				<input {...getInputProps()} data-testid="dropzone-input" />
				{
					isDragActive ?
						<p>Drop the file here ...</p> :
						<p>Drop file here or click to select a file</p>
				}
			</div>
		</div>
	);
}

Dropzone.propTypes = {
	onReadFile: PropTypes.func,
	disabled: PropTypes.bool,
};

export default Dropzone;