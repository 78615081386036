import * as types from '../../constants/multitenancy';

const initialState = {
	countries: [],
	selectedCountry: null
};

function MultitenancyReducer(state = initialState, action) {
	switch (action.type) {
        case types.SET_COUNTRY:
            return {
				...state,
				countries: action.countries
			};
		default:
			return state
	}
}

export default MultitenancyReducer;