import { combineReducers } from 'redux';
import ServiceReducer from './reducers/ServiceReducer';
import OrderingReducer from './reducers/OrderingReducer';
import BlacklistReducer from './reducers/Blacklist';
import VinrouterReducer from './reducers/Vinrouter';
import MultitenancyReducer from './reducers/Multitenancy';
import UsersReducer from './reducers/UsersReducer';
import ReportsReducer from './reducers/ReportsReducer';

const rootReducer = combineReducers({
	service: ServiceReducer,
	ordering: OrderingReducer,
	blacklist: BlacklistReducer,
	vinrouter: VinrouterReducer,
	multitenancy: MultitenancyReducer,
	users: UsersReducer,
	reports: ReportsReducer,
});

export default rootReducer;