
const columns = (overrides) => {

	const config = {
		columns: [
			'name', 'email', 'country', 'role', 'actions'
		],
		form: {
			editable: ['name', 'email', 'country', 'role'],
			select: [{ key: 'role', data: []}, { key: 'country', data: []}],
			onSubmit: () => {
				return null;
			}
		},
		options:{
			column: 'actions',
			render: () => null		
		},
		...overrides
	};

	const _columns = config.columns.map((col) => {

		const { form, options } = config;

		return {
			title: col.charAt(0).toUpperCase() + col.slice(1),
			dataIndex: col,
			key: col,
			onCell: record => {
				const select = form.select.filter(input => input.key === col);

				return {
					record,
					col,
					editable: form.editable.indexOf(col) !== -1,
					input: {
						type: select.length ? 'select': 'text',
						data: select.length ? select[0].data: null,
						disabled: select.length ? select[0].disabled: false,
						value: select.length ? select[0].value: null,
					},
					isOptions: options.column === col? options: false,
				};
			},
		};
	});

	return {
		columns: _columns
	};

};

export default columns;