import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from '../../pages/LoginPage/LoginPage';
import ServiceConfPage from '../../pages/ServiceConfPage';
import VinRouterPage from '../../pages/VinRouterPage';
import BlacklistPage from '../../pages/BlacklistPage';
import UsersPage from '../../pages/UsersPage';
import ReportsPage from '../../pages/Reports/ReportsPage';
import { useCognito } from '../../../services/Authenticator';

export function Admin() {
	const [role, setRole] = useState();
	const { user, session } = useCognito();

	useEffect(() => {
		if (user) {
			const u = user;

			if ('cognito:groups' in u) {
				setRole(u['cognito:groups'][0]);
			}
		}
	}, [user]);

	const shouldStart = () => {
		if (session && session.accessToken && session.accessToken.jwtToken) {
			return true;
		}

		return false;

	};

	if (!role && window.location.pathname !== '/login') return false;

	return (
		<div data-testid="admin-app">
			<Switch>
				<Route path="/login" render={() => <LoginPage />} />

				{role !== 'user' && (
					<Route
						path="/services"
						component={() => (
							<ServiceConfPage
								shouldStart={shouldStart()}
							/>
						)}
					/>
				)}

				{role !== 'user' && (
					<Route
						path="/users"
						component={() => (
							<UsersPage
								shouldStart={shouldStart()}
							/>
						)}
					/>
				)}

				{role !== 'user' && (
					<Route
						path="/blacklists"
						component={() => (
							<BlacklistPage
								shouldStart={shouldStart()}
							/>
						)}
					/>
				)}

				{role !== 'user' && (					
					<Route
						path="/reports"
						component={() => (
							<ReportsPage
								shouldStart={shouldStart()}
							/>
						)}
					/>
				)}

				{role !== 'user' && (					
					<Route
						path="/reports"
						component={() => (
							<ReportsPage
								shouldStart={shouldStart()}
							/>
						)}
					/>
				)}

				<Route
					path="/vin-router"
					component={() => (
						<VinRouterPage
							shouldStart={shouldStart()}
						/>
					)}
				/>

				{(role === 'user' && window.location.pathname !== '/vin-router') && (
					<Redirect to="/vin-router" />
				)}

				<Route render={() => <Redirect to="/services" />} />
			</Switch>
		</div>
	);
}

Admin.propTypes = {

};

export default Admin;