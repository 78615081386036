import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import SideMenu from '../../organisms/SideMenu';
import './Page.scss';
import HeaderMenu from '../../organisms/HeaderMenu/HeaderMenu';

function Page({ children, onStart }) {
   
	const { Content } = Layout;
	
	useEffect(() => {
		if(onStart) onStart();
	},[onStart]);

	return (
		<Layout data-testid="page-template" className="page-template">
			<SideMenu />
			<Layout className="page-template__body">
				<HeaderMenu />
				<Content className="page-template__content">
					{children}
				</Content>
			</Layout>
		</Layout>
	);
}

Page.propTypes  = {
	children: PropTypes.object,
};

export default Page;


