import 'react-notifications/lib/notifications.css';

import React, { useState, useEffect } from 'react';

import vinDecoder from 'vin-decode';
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTime, differenceInMilliseconds } from 'date-fns';
import * as vinRouterActions from '../../../redux/actions/VinrouterActions';
import {
	Row,
	Select,
	Button,
	Form,
	Divider,
	Col,
	Empty,
	Tabs,
	Table,
	Input,
} from 'antd';
import ReactJson from 'react-json-view';
import { useCognito } from '../../../services/Authenticator';

const { Option } = Select;

/*
  responses from the pitlane step function
    when part is not stored in dynamo
*/
const responseWhenProcessing = 'We are still processing your request';
const responseWhenRequested =
  'Vin received and we\'re processing it, try again later';

function RunVinRouterComponent({ form, orders, runOrder, result, loadOrders }) {
	const [fields, setFields] = useState([]);

	const { getFieldDecorator, getFieldValue, resetFields } = form;

	/*
    object containing all users info
      from aws cognito for current
      session
  */
	const { user } = useCognito();

	/*
    array containing orders id
      from orders select field
  */
	const _orders = getFieldValue('orders') || [];


	/*
    state used to store all parameters
      coming from antd form
  */
	const [params, setParams] = useState({
		country: '',
		hole: '',
		make: '',
		model: '',
		orders: [],
		origin: '',
		trim: '',
		vin: '',
		year: '',
		variant: ''
	});

	/*
    boolean state used to render run button spinning
  */
	const [loading, setLoading] = useState(false);

	/*
    boolean state used to handle run 
      button disabling and enabling
  */
	const [enableSubmit, setEnableSubmit] = useState(false);

	/*
    state used to store
      the user role from
      current session
    (can be removed in further fixes, user from cognito already has this info)
  */
	const [role, setRole] = useState();

	/*
    usestate for country
  */
	const [country, setCountry] = useState();
	/*
    useState for pitlane cacheing
    */
	const [count, setCount] = useState(0);
	/*
    storing timestamp for
      each request
      */
	const [timestamp, setTimestamp] = useState();
	const [finalTimestamp, setFinalTimestamp] = useState();
	/*

  */
	const [uuid, setUuid] = useState();

	useEffect(() => {
		setCountry(localStorage.getItem('country'));
	}, [localStorage.getItem('country')]);

	useEffect(() => {
		setFields([
			'vin',
			'make',
			'model',
			'year',
			'country',
			'origin',
			'hole',
			'trim',
			'variant'
		]);
	}, [country]);

	useEffect(() => {
		if (orders) if (orders.length === 0) loadOrders();
	});

	useEffect(() => {
		const aux = params;
		aux.orders = _orders || [];

		setParams(aux);
	}, [_orders]);

	useEffect(() => {
		if (params) {
			if (params.vin && params.orders.length) {
				setEnableSubmit(true);
			} else {
				setEnableSubmit(false);
			}
		}
	}, [params, _orders]);

	useEffect(() => {
		resetFields();
	}, [orders, resetFields]);

	useEffect(() => {
		if (user && user['cognito:groups'] && !role) {
			setRole(user['cognito:groups'][0]);
		}
	}, [user]);

	/*
    useEffect function responsible for
      checking if pitlane function returned part
      or is still checking for it in stepfunction
  */
	useEffect(() => {
		const delay = (ms) => new Promise((res) => setTimeout(res, ms));
		setCount(count + 1);

		async function checkResponse() {
			if (
				result &&
        (result[0].payload === responseWhenProcessing ||
          result[0].payload === responseWhenRequested)
			) {
				await delay(10000);
				await reloadSubmit();
			} else if (result) {
				setFinalTimestamp(getTime(new Date()));
				setLoading(false);
			}
		}

		checkResponse();
	}, [result]);

	const handleError = () => {
		setLoading(false);
	};

	/*
    function responsible for calling axios api
      again in case of no response from pitlane
  */
	const reloadSubmit = async () => {
		try {
			runOrder(params, uuid).then();
		} catch (e) {
			handleError();
		}
	};

	const handleChange = () => {
		form.validateFields((err, values) => {
			
			if( values.orders == null ){
				
				values.orders = [];
			}
			setParams(values);
		});
	};

	const fnSubmit = (e) => {
		e.preventDefault();
  
		const actualUuid = uuidv4();
		setUuid(actualUuid);

		if (!vinDecoder(params.vin)) {
			console.log(params.vin);
			NotificationManager.error('Please, check your number and try again!', 'Invalid VIN', 5000);
			return;
		}

		if (enableSubmit) {
			form.validateFields(async (err, values) => {
				setLoading(true);
				setFinalTimestamp(new Date());
				setTimestamp(getTime(new Date()));

				setCount(0);
				runOrder(params, actualUuid).then().catch(handleError);
			});
		}
	};

	const nameOrdering = (array) => {
		const services = [];

		array
			.sort((a, b) => {
				return a.run_order > b.run_order
					? 1
					: b.run_order > a.run_order
						? -1
						: 0;
			})
			.forEach((v) => services.push(v.name));

		return services.join(', ');
	};

	return (
		<>
			<div data-testid='run-vin-router'>
				<Form onChange={handleChange} autoComplete='off' onSubmit={fnSubmit}>
					<Row>
						<h2>Search Eurocode</h2>
						<Col md={12}>
							<Form.Item label='Select orders' hasFeedback help={null}>
								{getFieldDecorator('orders', {
									getValueFromEvent: (value) => value.slice(0, 2),
									initialValue: orders && orders.length ? [orders[0].id] : null,
									rules: [
										{
											required: true,
										},
									],
								})(
									role === 'user' ? (
										<Select
											style={{ width: '100%' }}
											placeholder=''
											allowClear
											mode='multiple'
											data-testid='select-orders'
											loading={orders ? orders.length === 0 : false}
											disabled={true}
										>
											{orders &&
                        orders.map((order, i) => {
                        	return (
                        		<Option key={i} value={order.id}>
                        			{order.id}
                        			{' - '}
                        			{nameOrdering(order.items)}
                        		</Option>
                        	);
                        })}
										</Select>
									) : (
										<Select
											style={{ width: '100%' }}
											placeholder=''
											allowClear
											mode='multiple'
											data-testid='select-orders'
											loading={orders ? orders.length === 0 : false}
										>
											{orders &&
                          orders.map((order, i) => {
                          	return (
                          		<Option key={i} value={order.id}>
                          			{order.id}
                          			{' - '}
                          			{nameOrdering(order.items)}
                          		</Option>
                          	);
                          })}
										</Select>
									),
								)}
							</Form.Item>
						</Col>
					</Row>

					<Divider />

					<Row>
						{fields.map((field, i) => (
							<Col md={7} style={{ marginRight: 10 }} key={i}>
								<Form.Item
									help={null}
									hasFeedback={!['vin'].indexOf(field)}
									validateStatus={
										!(country === 'be') && (!['vin'].indexOf(field) && !getFieldValue(field))
											? 'error'
											: null
									}
								>
									{getFieldDecorator(field, {
										initialValue: '',
										rules: [
											{
												required: !['vin'].indexOf(field),
											},
										],
									})(
										<Input
											key={i}
											addonBefore={
												<span style={{ textTransform: 'capitalize' }}>
													{field}
												</span>
											}
										/>,
									)}
								</Form.Item>
							</Col>
						))}
					</Row>
					<br />
					<Row>
						<Form.Item>
							<Button
								style={{ width: '25%' }}
								type='primary'
								htmlType='submit'
								loading={loading}
								disabled={!enableSubmit}
								data-testid='run-order-button'
							>
                Run
							</Button>
						</Form.Item>
					</Row>
				</Form>

				<br />

				<Row>
					{!result ||
            result[0].payload === responseWhenProcessing ||
            result[0].payload === responseWhenRequested ? (
							<Empty />
						) : (
							<Tabs defaultActiveKey={'0'}>
								<Tabs.TabPane tab='Result' key={'0'}>
									<Col md={24}>
										<Table
											columns={[
												{
													key: 'order',
													title: 'Order',
													dataIndex: 'order',
												},
												{
													key: 'partnumber',
													title: 'Partnumber',
													dataIndex: 'payload',
												},
												{
													key: 'service',
													title: 'Service',
													dataIndex: 'service',
												},
												{
													key: 'time',
													title: 'Time',
													dataIndex: 'execution_time',
												},
											]}
											dataSource={result.map((o) => {
												var services = Object.keys(o.services).map((k) => ({
													...o.services[k],
													service: k,
												}));
												var service = services.filter((s) => s.response !== '');

												let products = o.payload;
												if (o.payload.products && o.payload.products.length) products = o.payload.products.toString();

												let service_time = parseFloat(differenceInMilliseconds(finalTimestamp, timestamp) / 1000);
												o.execution_time = `${service_time} s`;

												if ((o.service === 'Pitlane' || o.service === 'Pitlane-Dev') && count === 1) {
													if (o.service_cached) o.service = 'Pitlane Cache';
													else o.service = 'Vinrouter Cache';
												}

												return {
													...o,
													payload: products,
													services:
                            service.length === 0 ? 'None' : service[0].service,
												};
											})}
										/>
									</Col>
								</Tabs.TabPane>
								<Tabs.TabPane tab='Raw' key={'1'}>
									{result.map(
										(object, i) =>
											i <= 2 && (
												<Col md={12} key={i}>
													<ReactJson
														key={i}
														name={false}
														data-testid='run-order-result'
														src={object}
													/>
												</Col>
											),
									)}
								</Tabs.TabPane>
							</Tabs>
						)}
				</Row>

				<NotificationContainer />
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	orders: state.vinrouter.orders,
	result: state.vinrouter.result,
	services: state.service,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(vinRouterActions, dispatch);

export const RunVinRouter = Form.create({ name: 'run-vin-router' })(
	RunVinRouterComponent,
);

RunVinRouterComponent.propTypes = {
	form: PropTypes.object,
	orders: PropTypes.array,
	runOrder: PropTypes.func,
	result: PropTypes.array,
	loadOrders: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RunVinRouter);
