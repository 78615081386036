import React from 'react';
import logo from './logo.png';
import './Logo.scss';

const Logo = () => (
	<div 
		className={'logo'} 
		data-testid="logo"
	>
		<img
			alt="Belron"
			src={logo}
		/>
	</div>
);

export default Logo;
