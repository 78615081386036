import React from 'react';
import { Layout } from 'antd';
import Logo from '../../atoms/Logo';
import Menu from '../../molecules/NavMenu';
import './SideMenu.scss';

function SideMenu() {

	const { Sider } = Layout;

	return (
		<Sider
			breakpoint="lg"
			collapsedWidth="0"
			className="side-menu"
			data-testid="side-menu"
		>
			<Logo />
			<Menu />
		</Sider>
	);
}

export default SideMenu;
