
const Reader = ({ files }) => {

    return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.onload = function (e) {
            var contents = e.target.result;
            try {
                const file = contents;
                resolve(file);

            } catch (error) {
                resolve(false);
            }
           
        };
        reader.readAsText(files[0]);

    });
   
};

export default Reader;