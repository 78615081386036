import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, message, Divider } from 'antd';
import Dropzone from '../../molecules/Dropzone';
import DropzoneList from '../../molecules/DropzoneList/DropzoneList';

function LoadOrder({ show, close, onOk }) {

	const [order, setOrder] = useState([]);
	const [file, setFile] = useState([]);

 
	return (
		<Modal
			visible={show}
			title="Load an order"
			onCancel={close}
			okText="Load"
			okButtonProps={{
				// disabled: !file.length
			}}
			onOk={() => { 
				onOk(order);
				setFile([]);
			}}
		>
			<>
				<Dropzone
					onReadFile={(json) => {
						if(Array.isArray(json.data)){
							setFile([json]);
							setOrder(json.data);
						}else{
							message.error('This file isn\'t valid.');
						}
					}}
				/>
				<Divider />
				<DropzoneList 
					items={file}
					onRemove={(name)=>{
						setFile(file.filter(f => f.name !== name));
					}} 
				/>
			</>
		</Modal>
	);
}

LoadOrder.propTypes = {
	show: PropTypes.bool,
	close: PropTypes.func,
	onOk: PropTypes.func
};

export default LoadOrder;
