import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderingList from '../../molecules/OrderingList';
import { Button, Empty, Row, Col, message, Spin } from 'antd';
import * as orderingActions from '../../../redux/actions/OrderingActions';
import * as serviceActions from '../../../redux/actions/ServiceActions';
import * as vinRouterActions from '../../../redux/actions/VinrouterActions';
import { bindActionCreators } from 'redux';
import LoadOrder from '../LoadOrder';
import { exportFile } from '../../../services/Utils';
import './ServiceOrdering.scss';



export function ServiceOrdering({ services, ordering, saveOrder, loadOrder, loadOrders }) {

	const [openLoadOrder, setOpenLoadOrder] = useState(false);
	const [didUpdate, setDidUpdate] = useState(false);
	const [ loading, setLoading ] = useState(true);

	useEffect(() => {
		setDidUpdate(JSON.stringify(ordering) !== JSON.stringify(services));
	}, [ordering, services]);

	useEffect(()=> {
		if(services.length === 0){
			setLoading(false);
		}
	}, [ services ]);

	return (
		<div className="service-ordering" data-testid="service-ordering">
			<Row style={{textAlign: 'right', paddingBottom:10}}>
				<Col>
					<Button 
						type="dashed" 
						shape="circle" 
						icon="download" 
						onClick={()=>{
							const content = JSON.stringify(services.map(({
								id,
								name,
								order_id
							}) => ({
								id,
								name,
								order_id
							})));
							exportFile(content, 'application/json');
						}}
					/>
				</Col>
			</Row>

			<Row>
				{(services.length && services[0])?(
					<>
						<LoadOrder 
							show={openLoadOrder}
							close={()=> setOpenLoadOrder(false)}
							onOk={(order)=>{
								setOpenLoadOrder(false);
								saveOrder(order).then(() => {
									message.success('The run order was saved successfully.');
									loadOrder(order);
								});
							}}
						/>
					
						<OrderingList />

						<div className="service-ordering__controls">
							<Button
								type="primary"
								onClick={()=>setOpenLoadOrder(true)}
							>
							Load
							</Button>
							{' '}
							<Button
								type="primary"
								className="save-order"
								disabled={!didUpdate}
								onClick={()=>{
									saveOrder(ordering).then((m)=>{
										if(!m){
											setDidUpdate(false);
											message.success('The run order was saved successfully.');
											loadOrders();
										}else{
											message.error(`${m} The order already exists.`);
										}

									});
								}}
								data-testid="service-ordering-save"
							>
							Save
							</Button>
						</div>
					</>
				):(
					<div style={{textAlign: 'center', paddingTop:10}}>
						{loading ? (
							<Spin />
						):(
							<Empty 
								description="No services loaded"
							/>
						)}
					</div>
				)}
			</Row>
			
		</div>
		
	);
}

ServiceOrdering.propTypes = {
	services: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	})),
	saveOrder: PropTypes.func,
	loadOrder: PropTypes.func,
	ordering: PropTypes.array,
};

const mapStateToProps = state => ({
	services: state.service,
	ordering: state.ordering
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({...orderingActions, ...serviceActions, ...vinRouterActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrdering);