import React from 'react';
import Page from '../../templates/Page';
import { Row } from 'antd';
import BlacklistTable from '../../molecules/BlacklistTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as blacklistActions from '../../../redux/actions/BlacklistActions';
import * as serviceActions from '../../../redux/actions/ServiceActions';

export function BlacklistPage() {
	return (
		<Page>
			<Row
				className="blacklist-page"
				data-testid="blacklist-page"
			>
				<h2>Blacklists</h2>
				
				<BlacklistTable />
			</Row>
		</Page>
	);
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({...blacklistActions, ...serviceActions}, dispatch);

const mapStateToProps = state => ({
	blacklists:  state.blacklist.blacklists,
	services:  state.service.services
});

export default connect(mapStateToProps, mapDispatchToProps)(BlacklistPage);
