import _ from 'lodash';

export const Search = ( array, searchParams ) => {

    const { key, value } = searchParams;
    
    const _array = _.filter(array, o => {
        if(o[key]){
            return o[key].toLowerCase().indexOf(value.toLowerCase()) !== -1;
        }
        return false;
    });

    return _array;
}