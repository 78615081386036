import { CognitoAuth } from 'amazon-cognito-auth-js';

import { clientId, userPoolId, cognitoWebDomain } from '../../../config';

const origin = window.location.origin;

const Cognito = new CognitoAuth({
	TokenScopesArray: ['openid'],
	ClientId: clientId,
	UserPoolId: userPoolId,
	RedirectUriSignIn: `${origin}/services`,
	RedirectUriSignOut: `${origin}/login`,
	AppWebDomain: cognitoWebDomain
});

export default Cognito;

