const columns = [
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
		editable: false,
		align: 'center',
	},
	{
		title: 'Actions',
		dataIndex: 'actions',
		key: 'actions',
		editable: false,
		align: 'center',
	},
];

export default columns;