import * as types from '../../constants/order';

const initialState = [];

function OrderingReducer(state = initialState, action) {
	switch (action.type) {

		case types.SET_ORDER:
			return action.order;

		case types.ADD_ORDER: 
			return [
				...state,
				action.order
			];
			
		default:
			return state
	}
}

export default OrderingReducer;