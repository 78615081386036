import * as types from '../../constants/services';
import * as otypes from '../../constants/order';
import * as btypes from '../../constants/blacklist';
import Api from '../../../services/Api/Api';

export const loadServices = (files) => {
    
	return dispatch => {
		return new Promise((resolve, reject) => {
			files.forEach((file, i) => {

				const payload = {
					collection: file.data,
					client_certificate_auth: (file.private && file.certificate) ? (
						{
							private: file.private,
							certificate: file.certificate
						}
					): {}
				};

				Api.post('services', payload).then(({data})=> {
                    
					if(data.message) {
						resolve(data.message);

						return;
					}

					dispatch({
						type: types.ADD_SERVICE,
						services: data.item
					});

					dispatch({
						type: otypes.ADD_ORDER,
						order: data.item
					});

					if((i+1) === files.length) resolve(true);

				}).catch(err=>reject(err));
			});
		});
	};
};

export const getServices = () => {
    
	return dispatch => {

		dispatch({
			type: types.LOAD_SERVICES,
			services: [undefined]
		});

		return Api.get('services').then(({data})=> {

			const services = data.item.map(item => ({ id: item.id, name: item.name, order_id: data.order_id, template: data.template }));

			dispatch({
				type: types.LOAD_SERVICES,
				services
			});

			dispatch({
				type: otypes.SET_ORDER,
				order: services
			});
            
		}).catch(err=>console.log(err));

	};
};

export const deleteService = (id) => {
    
	return (dispatch, getState) => {
		const { service, blacklist } = getState();

		const services = service.filter(s => s.id !== id);
		return Api.delete(`services?id=${id}`).then(({data})=> {
           
			dispatch({
				type: types.LOAD_SERVICES,
				services
			});

			dispatch({
				type: otypes.SET_ORDER,
				order: services
			});

			const deleteBlacklists = blacklist.blacklists.filter(b => {
				return b.service.id !== id;
			});

			dispatch({
				type: btypes.SET_BLACKLISTS,
				blacklists: deleteBlacklists,
				total_count: deleteBlacklists.length
			});

		}).catch(err=>console.log(err));
	};
};
