import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Button, Dropdown } from 'antd';
import Menu from '../../molecules/NavMenu';
import SelectCountry from '../../atoms/SelectCountry';
import './HeaderMenu.scss';

function HeaderMenu({ dark }) {
	const { Header } = Layout;

	const menu = (
		<Menu />
	);

	return (
		<Header 
			className={`header ${dark && 'bg-dark'}`} 
			data-testid="header-menu"
		>
			<Dropdown overlay={menu} placement="bottomCenter">
				<Button ghost  icon="menu" className="header-menu-button" />
			</Dropdown>

			<SelectCountry />

		</Header>
	);
}

HeaderMenu.propTypes = {
	dark: PropTypes.bool,
};

HeaderMenu.defaultProps = {
	dark: false,
};

export default HeaderMenu;
