import Api from '../../../services/Api/Api';
import * as types from '../../constants/blacklist';
import Axios from 'axios';

export const getBlacklists = (refresh = false) => {
	return dispatch => {
		dispatch({
			type: types.SET_BLACKLISTS,
			blacklists: undefined
		});

		return Api.get('blacklist').then(({data})=> {
			dispatch({
				type: types.SET_BLACKLISTS,
				blacklists: data.blacklist,
				total_items: data.total_count,
				refresh
			});

		}).catch(err=>console.log(err));
	};
};

export const createBlacklist = (blacklist) => {
	return dispatch => {

		return Api.post('blacklist', blacklist).then(({data})=> {
			dispatch({
				type: types.ADD_BLACKLIST,
				blacklist: data.blacklist 
			});

		}).catch(err=>console.log(err));
	};
};


export const saveBlacklist = (blacklist) => {
	return dispatch => {

		return Api.put('blacklist', blacklist).then(({data})=> {
			dispatch({
				type: types.UPDATE_BLACKLIST,
				blacklist: { ...data.blacklist }
			});
			return data;
		});
	};
};

export const deleteBlacklist = (id) => {
	return dispatch => {
		return Api.delete(`blacklist?id=${id}`).then(({data})=> {

			dispatch({
				type: types.REMOVE_BLACKLIST,
				blacklist: {
					id
				}
			});

		}).catch(err=>console.log(err));
	};
};

export const loadBlacklistsCSV = (csv) => {
	return dispatch => {

		return Api.get('presigned_url?object_key=blacklists.csv').then(({ data }) => {
			const { url, fields } = data;
			const formData = new FormData();

			Object.keys(fields).forEach((key)=>{
				formData.append(key, fields[key]);
			});

			const file = new Blob([csv], { type: 'text/csv' });

			formData.append('file', file);

			const config = { headers: { 'Content-Type': 'multipart/form-data' } };

			return Axios.post(url, formData, config).then((data) => {
				return data;
			});
		});

	};
};