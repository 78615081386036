import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Raw from '../../templates/Raw';
import { Card, Button, Row, Col } from 'antd';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import Logo from '../../atoms/Logo';
import './LoginPage.scss';
import { useCognito } from '../../../services/Authenticator';


function LoginPage() {
	const { signIn } = useCognito();
  
	useEffect(() => {
		const refreshFail = localStorage.getItem('Vinrouter:FailedToRefresh');
    
		if(refreshFail){
			NotificationManager.error('Your sessions has expired', 'LOGOUT', 5000);
		}
		
		localStorage.removeItem('Vinrouter:FailedToRefresh');
	}, []);

	return(
		<>
			<Raw>
				<Card
					className="login-page"
					data-testid="login-page"
				>
					<Row 
						type="flex" 
						justify="center"
					>
						<Logo />
					</Row>
					<Row 
						type="flex" 
						justify="center"
					>
						<Col md={12} xs={12} sm={12}>
							<Button
								data-testid="signin-button"
								size="large"
								type="primary"
								key="login"
								icon="login"
								block
								onClick={signIn}
							>
							Sign In
							</Button>
						</Col>
					</Row>
				
				</Card>
			</Raw>
			<NotificationContainer />
		</>
	);
}

LoginPage.propTypes = {
	signIn: PropTypes.func
};

export default LoginPage;