import React from 'react';
import { UserContext } from '../../organisms/UsersTable/UsersTable';
import { Select, Form, Input } from 'antd';

function TableInput(props) {

	const { record, children, input, col, isOptions } = props;

	const OptionsComponent = isOptions.render;

	return (
		<td>
			{(record.new) ? (
				<UserContext.Consumer>
					{
						form => (
							!isOptions ? (
								<Form.Item hasFeedback>
									{form.getFieldDecorator(col ,{
										// initialValue: input.value ? input.value : false,
										rules: [{
											required: true,
											message: ''
										}]
									})(
										input.type === 'text' ? (
											<Input
												key={col}
												placeholder={`Your ${col}`}
											/>
										) : (
											<Select
												style={{ width: '200px' }}
												showSearch
                        allowClear
                        placeholder={`Select a ${col}`}
												disabled={input.disabled}
                        mode={col === 'role' ? 'multiple' : 'default'}
											>
                        {col !== 'role' &&
                          <Select.Option
                            key={`selectOption-${col}`}
                            value={null}
                          >
                            Select a {col}
                          </Select.Option>
                        }
												{input.data && input.data.map(opt => (
													<Select.Option 
														key={opt.key}
														value={opt.key}
													>
														{opt.value}
													</Select.Option>
												))}
											</Select>
										)
									)}
								</Form.Item>
							): (
								<OptionsComponent 
									record={record}
									form={form}
								/>
							)
						)
					}
				</UserContext.Consumer>
			) : children}
		</td>
	);
}

export default TableInput;