/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCountries } from '../../../redux/actions/MultitenancyActions';
import { getServices } from '../../../redux/actions/ServiceActions';
import { getBlacklists } from '../../../redux/actions/BlacklistActions';
import { getUsers } from '../../../redux/actions/UsersActions';
// import { getReports } from '../../../redux/actions/ReportsActions';
import { loadOrders } from '../../../redux/actions/VinrouterActions';
import { Select, Tag, Spin } from 'antd';
import './SelectCountry.scss';
import { useCognito } from '../../../services/Authenticator';

const { Option } = Select;

export function SelectCountry(
	{
		countries,
		blacklists,
		services,
		getCountries,
		getServices,
		getUsers,
		// getReports,
		getBlacklists,
		getOrders
	}) {

	const [role, setRole] = useState();
	const [country, setCountry] = useState();
	const { user, session } = useCognito();

	useEffect(() => {
		if (user && user['custom:country'] && localStorage.country === undefined) {
			setCountry(user['custom:country']);
			localStorage.setItem('country', user['custom:country']);
		} else {
			setCountry(localStorage.country);

		}

		if (user && user['cognito:groups'] && !role) {
			setRole(user['cognito:groups'][0]);
		}

	}, []);

	useEffect(() => {
		if (countries.length === 0) {
			if (session) {
				if (session.getIdToken().getJwtToken() !== '') {
					getCountries();
				}
			}
		}
	}, [countries, getCountries]);


	const isLoading = () => {
		return user === undefined || countries.length === 0;
	};

	const getData = () => {
		if (session) {
			if (session.getIdToken().getJwtToken() !== '') {
				getServices();
			  getUsers();
				// getReports();
				getBlacklists();
				getOrders();
			}
		}
	};

	useEffect(() => {

		if (
			(blacklists === false && (!services || services[0] === undefined))
		) {
			getData();

		}

	}, [session]);


	if (!role) {
		return false;
	}

	return (
		<div
			className="select-country-wrapper"
			data-testid="select-country"
		>
			{ role === 'admin-global' ? (
				<Select
					id="select"
					className="select"
					showSearch
					loading={isLoading()}
					value={!isLoading() ? country : null}
					onChange={(value) => {
						setCountry(value);
						localStorage.setItem('country', value);
						getData();
					}}
				>
					{countries.map((country, key) => {
						const { name, code } = country;
						return (
							<Option
								key={key}
								value={code}
							>
								{`${code.toUpperCase()} - ${name}`}
							</Option>
						);
					})}
				</Select>
			) : (
				<Tag>
					{
						countries.length ?
							(
								countries
									.filter(c => c.code === country)
									.map(
										c => `${c.code.toUpperCase()} - ${c.name}`
									).pop())
							: (
								<Spin
									style={{
										margin: 5
									}}
									size={'small'}
								/>
							)
					}
				</Tag>
			)}
		</div>
	);
}

const mapDispatchToProps = dispatch => bindActionCreators({
	getCountries,
	getBlacklists,
	getServices,
	getUsers, 
	// getReports,
	getOrders: loadOrders
}, dispatch);

const mapStateToProps = state => ({
	countries: state.multitenancy.countries,
	selectedCountry: state.multitenancy.selectedCountry,
	blacklists: state.blacklist.blacklists,
	// reports: state.reports.reports,
	services: state.service.services,
	orders: state.vinrouter.orders,
});

SelectCountry.propTypes = {
	countries: PropTypes.array,
	getCountries: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCountry);