import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Switch } from 'antd';
import './DropzoneList.scss';
import CertificatesDialog from '../CertificatesDialog/CertificatesDialog';

function DropzoneList({ items, onRemove, onSetCertificates }) {
	
	const [ upload, setUpload ] = useState(false);
	const [ _file, setFile ] = useState();

	return (
		<>
			<CertificatesDialog 
				open={upload}
				close={() => setUpload(false)}
				onSetPrivate={ (content) => {
					onSetCertificates(_file.name, "private", content)
				}}
				onSetCertificate={ (content) => {
					onSetCertificates(_file.name, "certificate", content)
				}}
			/>
			<ul className="dropzone-list" data-testid="dropzone-list">
				{(items[0] && (
					<li
						key={items[0]}
						className={'service-item dropzone-list__item'}
						data-testid="dropzone-item"
					>	

						<span>
							<Button 
								type="dashed" 
								shape="circle" 
								icon="delete" 
								onClick={() => onRemove(items[0].name)} 
								data-testid="dropzone-remove-button"
							/>
						</span>
						<span className="service-name">{items[0].name}</span>
						{onSetCertificates ? (
							<Switch 
								checkedChildren={<Icon type="file-protect" />}
								unCheckedChildren={<Icon type="file-protect" />}
								checked={(
									items[0].certificate && items[0].private
								)}
								onChange={( checked ) => {
									setFile(items[0]);
									setUpload(true);
								}}
							/>
						): (
							<Icon type="drag"/>
						)}
						

					</li>
					)
				)}
			</ul>
		</>
	);
}

DropzoneList.propTypes = {
	items: PropTypes.array,
	onRemove: PropTypes.func
};
export default DropzoneList;
