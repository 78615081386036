/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';

const EditModal = ({ visible, setVisible, modalData, availableRoles, updateUser }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [roles, setRoles] = useState(modalData.role);

	const handleOk = async () => {
		setIsLoading(true);
		const userData = {
			...modalData,
			role: roles,
		};
		try {
			await updateUser(userData);
		} catch (error) {
			return error;
		} finally {
			setIsLoading(false);
			setVisible(false);
		}
	};

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			<Modal
				title={`Edit ${modalData.name} roles`}
				visible={visible}
				onOk={handleOk}
				maskClosable={false}
				confirmLoading={isLoading}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
            Cancel
					</Button>,
					<Button key="submit" loading={isLoading} onClick={handleOk} style={{ backgroundColor: '#108ee9', color: 'white' }}>
            Submit
					</Button>
				]}
			>
				<Form layout="vertical">
					<Form.Item label="User">
						<Input disabled value={modalData.name} />
					</Form.Item>
					<Form.Item label="Country">
						<Input disabled value={modalData.country} />
					</Form.Item>
					<Select
						showSearch
						allowClear
						mode='multiple'
						value={roles}
						onChange={(value) => setRoles(value)}
					>
						{availableRoles && availableRoles.map(opt => (
							<Select.Option 
								key={opt.key}
								value={opt.key}
							>
								{opt.value}
							</Select.Option>
						))}
					</Select>
				</Form>
			</Modal> 
		</>
	);
};

export default EditModal;
