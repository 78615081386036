/* eslint-disable react/prop-types */
import React from 'react';
import Page from '../../templates/Page';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../../redux/actions/UsersActions';
import './UsersPage.scss';
import UsersTable from '../../organisms/UsersTable';

export function UsersPage({ 
	users,
	addUser,
	toggleUserStatus,
	resetPassword,
	deleteUser,
	countries,
	updateUser
}) {

	return (
		<Page>
			<Row
				className="users-page"
				data-testid="users-page"
			>
				<UsersTable
					users={users}
					countries={countries}
					addUser={addUser}
					toggleUserStatus={toggleUserStatus}
					deleteUser={deleteUser}
					resetPassword={resetPassword}
					updateUser={updateUser}
				/>
			</Row>

		</Page>

	);
}

const mapDispatchToProps = dispatch => bindActionCreators(userActions, dispatch);

const mapStateToProps = state => ({
	users: state.users.users,
	countries: state.multitenancy.countries,
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
