import React from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import Admin from './lib/components/app/Admin';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './lib/redux/store';
import Authenticator from './lib/services/Authenticator/Authenticator';

const App = () => {

    return(
        <Provider store={store}>
            <Router>
                <Authenticator 
                    loginPath="/login"
                    signedRedirect="/services"
                >
                    <Admin />
                </Authenticator>
            </Router>
        </Provider>     
    )
};

ReactDOM.render(<App />, document.getElementById('root'));
