import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Session from './Session';

export const Auth = React.createContext();

export const useCognito = () => {
	const { session } = useContext(Auth);
	return session;
};

function Authenticator({ children, history, location, loginPath, signedRedirect}) {
    
	const [session, setSession] = useState({});

	useEffect(()=>{
		setSession(
			Session(location,history, {
				routes: {
					auth: loginPath,
					home: signedRedirect
				}
			})
		);
	}, [ history, location, loginPath, signedRedirect ]);

	const { Provider } = Auth;
	
	return (
		<div data-testid="authenticator">
			<Provider
				value={{ session }}
			>
				{children}
			</Provider>
		</div>
		
	);
	
}

Authenticator.propTypes = {
	children: PropTypes.object, 
	history: PropTypes.object, 
	location: PropTypes.object, 
	loginPath: PropTypes.string, 
	signedRedirect: PropTypes.string
};

export default withRouter(Authenticator);