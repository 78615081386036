import * as types from '../../constants/vinrouter';

const initialState = {
    orders: [],
    result: undefined
};

function VinrouterReducer(state = initialState, action) {
	switch (action.type) {
		case types.LOAD_ORDERS:
			return{
                ...state,
                orders: action.orders
            };
        case types.SET_RESULT:
			return{
                ...state,
                result: action.result
            };
		default:
			return state
	}
}

export default VinrouterReducer;