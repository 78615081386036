import moment from 'moment';

export const exportFile = (content, type) => {

	const format = type.split('/')[1];
	const fileName = `Belron-export-${moment().format('YYYY-MM-DD-hh:mm')}.${format}`;
	var a = document.createElement('a');
	a.setAttribute('type', 'hidden'); // make it hidden if needed

	var file = new Blob( [ content ], { type });
	a.href = URL.createObjectURL(file);
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	a.remove();
};

export const downloadFile = (url, filename, callback) => {
	const a = document.createElement('a');
	a.setAttribute('type', 'hidden');
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	a.remove();

	if( callback ){
		callback();
	}
};

export const readFile = (file) =>  {

	const files =  [ file ];
	return new Promise((resolve, reject) => {

		const reader = new FileReader();
		reader.onload = function (e) {
			var contents = e.target.result;
			try {
				resolve(contents);
			} catch (error) {
				resolve(false);
			}
           
		};
		reader.readAsText(files[0]);

	});
};