import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Form, Modal, Button, Upload, notification, Icon, Tag } from 'antd';
import columns, { getColumnSearchProps } from './columns';
import EditableCell from '../../atoms/EditableCell';
import TableOptions from '../../atoms/TableOptions';
import * as blacklistActions from '../../../redux/actions/BlacklistActions';
import { bindActionCreators } from 'redux';
import Api from '../../../services/Api/Api';
import { exportFile, readFile } from '../../../services/Utils';
import { Search } from '../../../services/Search';
import './BlacklistTable.scss';

export const EditableContext = React.createContext();

const { confirm, error } = Modal;

const checkUploadState = async (setState) => {
	const { data } = await Api.get('/state');
	const { blacklist_updating } = data;
	if(data){
		if(blacklist_updating === 1){
			setState(true);
			return checkUploadState();
		}

		if(blacklist_updating === 0){
			return true;
		}
	}
	return;
};

function BlacklistTableComponent({ 
	blacklists, 
	form, 
	services,
	getBlacklists,
	saveBlacklist, 
	deleteBlacklist, 
	createBlacklist,
	loadBlacklistsCSV,
	total_items
}) {

	const [ editingKey, setEditingKey ] = useState();
	const [ isAdding, setIsAdding ] = useState();
	const [ isUploading, setIsUploading ] = useState(false);
	const [ data, setData ] = useState([]);
	const [ _data, _setData ] = useState();
	const [ file, setFile ] = useState();
	const [ loading, setLoading ] = useState(false);
	const [ exportLoading, setExportLoading ] = useState(false);
	const [ importLoading, setImportLoading ] = useState(false);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ showHashedVin, setShowHashedVin ] = useState(false);
  

	useEffect(()=> {
		checkUploadState(setIsUploading)
			.then((state)=> setIsUploading(false));
	},[isUploading]);

	useEffect(()=>{

		if(!blacklists) {
			return ;
		}

		const _data = blacklists.map((blacklist,i) => {
			return {
				...blacklist,
				...blacklist.item,
				key: i,
				service: blacklist.service.name,
				_service: blacklist.service,
			};
		});

		setData(_data);
	},[blacklists]);

	const isEditing = record => {
		return record.key === editingKey;
	};

	const handleAdd = (values, record) => {
		const { service, ...rest} = values;
		const newRecord = {
			id: record.id,
			service,
			item: {
				...rest,
			},
		};
		setLoading(true);
		
		createBlacklist(newRecord).then(()=>{

			setEditingKey(undefined);
			setLoading(false);
			setIsAdding(false);
		}).catch((err)=> error({
			title: err.code,
			content: err.message
		}));
	};

	const handleEdit = (values, record) => {
		const { service, ...rest} = values;
       
		const newRecord = {
			id: record.id,
			service,
			item: {
				...rest,
			},
		};
		setLoading(true);

		saveBlacklist(newRecord).then(()=>{
			setEditingKey(undefined);
			setLoading(false);
		});
	};

	const handleDelete = (record) => {

		confirm({
			title: 'Do you want to delete this blacklist?',
			content: 'When clicking the YES button, this blacklist will be deleted',
			okText: 'Yes',
			okType: 'danger',
			onOk: ()=> {
				setLoading(true);
				deleteBlacklist(record.id).then(()=>{
					setLoading(false);
				});
			},
			onCancel: () => setLoading(false),
			className: `confirm-test-id-${record.key}`
		});
	};

	const handleAddCancel = () => {
		setData(data.filter(d => d.key !== (data.length -1)));
		setIsAdding(false);
	};

	const handleImport = (data) => {

		if(importLoading === true) return;
		
		setImportLoading(true);
		setFile(data.file);
		setLoading(true);
		readFile(data.file.originFileObj).then(content => {
			
			loadBlacklistsCSV(content).then((blacklist)=>{
				const state = checkUploadState();
				state.then((_state)=>{
					setTimeout(() => {
						if(_state){
							getBlacklists().then(() => {
								setImportLoading(false);
								setFile(undefined);
								setLoading(false);
								notification.open({
									message: 'Upload Completed',
									placement: 'bottomRight',
									description: 'The CSV file was successfully uploaded.',
									icon: <Icon type="check" style={{ color: '#108ee9' }} />,
								});
							});
						}
					}, 2000);
				});
			}).catch(err=>console.error(err));
		}).catch( err => console.error(err));

	};

	const handleSearch = (searchParams, clearFilters) => {
		if(!searchParams.value){
			clearFilters();
			return;
		}

		const search = Search(data, searchParams);
		
		_setData(data);
		setData(search);
	};

	const handleReset = (clearFilters) => {
		if(_data){
			setEditingKey(undefined);
			setLoading(false);
			setData(_data);
			clearFilters();
		}
		
	};

	const _columns = columns.map(col => {

		if(col.onFilter && services[0]){
			col.filters = services.map(s => ({
				text: s.name,
				value: s.id
			}));
		}

		if(col.editable){
			let body = {
				onCell: record => ({
					record,
					inputType: col.isSelect ? 'select': 'text',
					services,
					dataIndex: col.dataIndex,
					title: col.title,
					value: col.isSelect? record: record[col.key],
					editing: isEditing(record),
					required: col.required? true: false
				}),
			};

			if(!col.onFilter && !col.isInfo){
				body = {
					...body, 
					...getColumnSearchProps(col.dataIndex, handleSearch, handleReset )
				};
			}

			if(col.isInfo){
				body = {
					...body,
					title: (
						<>
							<div className={'blacklist-column'}>
								<span
									className={'ant-table-column-title'}
								>
									{col.title}
								</span>

								<Icon
									type={showHashedVin ? 'eye-invisible' : 'eye'}
									onClick={() => {
										setShowHashedVin(!showHashedVin);
									}}
								/>

							</div>
						</>
					),
					render: (text) => {
						return showHashedVin ? (text) : <Tag color={'purple'}>{'Hidden Vin'}</Tag>;
					}

				};
			}

			return {
				...col,
				...body
			};
		}

		if(col.isOptions){
			return {
				...col,
				render: (text, record) => {
					return (
						<TableOptions 
							isAdding={isAdding}
							record={record}
							toggleEdit={isEditing(record)}
							setEditingKey={setEditingKey}
							onSave={handleEdit}
							onAdd={handleAdd}
							onDelete={handleDelete}
							onAddCancel={handleAddCancel}
							setLoading={setLoading}
						/>
					);
				}  
			};
		}

		return col;
	});

	return (
		<EditableContext.Provider
			value={form}
		>   
			<Button.Group>
				<Button
					data-testid="add-blacklist-button"
					icon="plus"
					disabled={editingKey !== undefined || isAdding || services.length === 0 || isUploading}
					onClick={()=>{
						setCurrentPage(1);
						const newRow = {
							key: data.length,
							newRow: true,
						};
						if(data.length === 0){
							setData([ newRow ]);

						}else{
							setData([ newRow, ...data ]);
						}
						setIsAdding(true);
					}}
				>
                    Add Blacklist
				</Button>

				<Button
					icon="download"
					loading={exportLoading}
					disabled={isUploading || data.length <= 0 || data[0].newRow}
					data-testid="export-csv-button"
					onClick={()=>{
						setExportLoading(true);

						Api.get('/blacklist?export=csv').then(({ data }) => {
							setExportLoading(false);
							exportFile(data, 'text/csv');   
						});
					}}
				>
                    Export CSV
				</Button>
				<Upload 
					onChange={(data) => handleImport(data)}
					showUploadList={false}
					openFileDialogOnClick={!file}
					customRequest={({ file, onSuccess }) => {
						setTimeout(() => {
							onSuccess('ok');
						}, 0);
					}}
					accept=".csv"
					id="blacklist-import"
				>
					<Button
						icon="upload"
						type={ file?'primary': 'default' }
						loading={importLoading}
						disabled={isUploading}
						data-testid="import-csv-button"
					>
						{
							(isUploading) ? 'The CSV is being loaded yet'
								: ((file ? file.name: undefined) || 'Import CSV')
						}
					</Button>
				</Upload>
			</Button.Group>
			
			{(services.length === 0 )&& (
				<p style={{color: 'red', margin:10}}>
					* There are no services available. Go to the <Link to="/services">services tab</Link>  to setup a service.
				</p>
			)}

			{(isUploading)&& (
				<p style={{color: 'red', margin:10}}>
					* The blacklist are still uploading.
				</p>
			)}

			<Table
				size="small"
				columns={_columns}
				data-testid="blacklist-table"
				dataSource={data}
				className="blacklist-table"
				bordered
				loading={loading === true || blacklists === undefined || isUploading}
				pagination={{
					pageSize: 10,
					current: currentPage,
					total: total_items,
					onChange: (page, pageSize) => {
						setCurrentPage(page);
					}
				}}
				scroll={{ x: '130%'}}
				components={{
					body: {
						cell:  EditableCell
					}
				}}
			/>
            
		</EditableContext.Provider>

	);
}

export const BlacklistTable = Form.create()(BlacklistTableComponent);

const mapStateToProps = state => ({
	blacklists: state.blacklist.blacklists,
	total_items: state.blacklist.total_items,
	services: state.service
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(blacklistActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BlacklistTable);