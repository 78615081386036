/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Divider, notification, Icon, Tag, Modal } from 'antd';
import getColumns from './columns';
import TableInput from '../../atoms/TableInput';
import { useCognito } from '../../../services/Authenticator';
import EditModal from '../../atoms/EditModal';

export const UserContext = React.createContext();

const { confirm } = Modal;

const disabledUserStyle = {
	background: '#fcfcfc',
	color: '#919191'
};

function UsersTableComponent({ users, form, countries, addUser, toggleUserStatus, deleteUser, resetPassword, updateUser }) {

	const [_users, setUsers] = useState(users);
	const [isAdding, setIsAdding] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [loadingTable, setLoadingTable] = useState(false);
	const [modalData, setModalData] = useState({});
	const [availableRoles, setAvailableRoles] = useState({});
	const [role, setRole] = useState();
	const { user } = useCognito();

	const handleVisible = (value) => {
		setIsVisible(value);
	};

	useEffect(() => {
		if (users) {
			setLoadingTable(false);
			setUsers([]);
			setIsAdding(false);
			// eslint-disable-next-line react/prop-types
			setUsers(users.map((user, i) => {
				const country = countries
					.filter(c => c.code === user.country)
					.map(c => c.name)
					.pop();
				const _user = {
					...user,
					key: i,
					country,
					role: user.role.map(r => <Tag key={Math.random() * 99} color={'purple'}>{r}</Tag>),
					actions: (
						<Button.Group>
							<Button
								icon={user.enabled ? 'unlock' : 'lock'}
								title="Lock/Unlock user"
								onClick={() => {
									setLoadingTable(true);
									toggleUserStatus(user)
										.then(() => {
											const userStatus = !user.enabled ? 'disabled' : 'enabled';
											notification.open({
												'message': 'User status changed',
												'description': `User ${user.email} ${userStatus}`
											});
											setLoadingTable(false);
										})
										.catch(() => {
											notification.open({
												message: 'Lock not completed',
												placement: 'bottomRight',
												description: 'Something went wrong',
												icon: <Icon type="close-circle" style={{ color: '#108ee9' }} />,
											});
										})
										.finally(() => setLoadingTable(false));
								}
								}
							>
							</Button>
							<Button
								icon="edit"
								title="Edit roles"
								onClick={() => {
									editUser(user, country);
								}}
							>
							</Button>
							<Button
								icon="reload"
								title="Reset Password"
								onClick={() => {
									confirm({
										title: 'Do you want to reset this user password?',
										content: 'Click YES to confirm the request',
										okText: 'Yes',
										okType: 'primary',
										onOk: () => {
											setLoadingTable(true);
											resetPassword(user)
												.then(() => {
													notification.open({
														'message': 'Password reseted',
														'description': `User ${user.email} will shortly receive an 
                                            email with instructions to reset the password`
													});
												})
												.catch(() => {
													setLoadingTable(false);
													notification.open({
														message: 'E-mail not sent',
														placement: 'bottomRight',
														description: 'Something went wrong',
														icon: <Icon type="close-circle" style={{ color: '#108ee9' }} />,
													});
												})
												.finally(() => setLoadingTable(false));
										},
										onCancel: () => setLoadingTable(false)
									});
								}}
							>
							</Button>
							<Button
								icon="delete"
								title="Delete User"
								onClick={() => {
									confirm({
										title: 'Do you want to delete this user?',
										content: 'When clicking the YES button, this user will be deleted',
										okText: 'Yes',
										okType: 'danger',
										onOk: () => {
											setLoadingTable(true);
											deleteUser(user)
												.then(() => {
													setLoadingTable(false);
													notification.open({
														'message': 'User deleted',
														'description': `User ${user.email} deleted successfully`
													});
												})
												.catch(() => {
													notification.open({
														message: 'User not deleted',
														placement: 'bottomRight',
														description: 'Something went wrong',
														icon: <Icon type="close-circle" style={{ color: '#108ee9' }} />,
													});
												});
										},
										onCancel: () => setLoadingTable(false)
									});
								}}
							>
							</Button>
						</Button.Group>
					),
				};
				return _user;
			}));
		} else {
			setLoadingTable(true);
		}
	}, [users]);

	if (user && user['cognito:groups'] && !role) {
		setRole(user['cognito:groups']);
	}

	const countrySelect = countries.map(country => (
		{
			key: country.code,
			value: `${country.code.toUpperCase()} - ${country.name}`
		})
	).filter(c => {
		if (role && role[0] === 'admin-country') {
			return c.key === localStorage.country;
		}
		return true;
	});

	const userAvailableRoles = () => {
		if (role && role[0] === 'admin-country') {
			return [
				{ key: 'admin-country', value: 'admin-country' },
				{ key: 'adas', value: 'adas' },
				{ key: 'user', value: 'user' },
			];
		} else {
			return [
				{ key: 'admin-global', value: 'admin-global' },
				{ key: 'admin-country', value: 'admin-country' },
				{ key: 'adas', value: 'adas' },
				{ key: 'user', value: 'user' },
			];
		}
	};
	const { columns } = getColumns({
		form: {
			editable: ['name', 'email', 'country', 'role'],
			select: [
				{
					key: 'role',
					value: (role && role[0] === 'admin-country') ? 'admin-country' : null,
					data: userAvailableRoles()
				},
				{
					key: 'country',
					value: (role && role[0] === 'admin-country') ? localStorage.country : null,
					data: countrySelect
				}
			],
		},
		options: {
			column: 'actions',
			// eslint-disable-next-line react/prop-types
			render: ({ form, record }) => (
				<Button.Group>
					<Button
						icon="check"
						onClick={() => {
							// eslint-disable-next-line react/prop-types
							form.validateFields((err, values) => {
								if (!err) {
									setLoadingTable(true);
									// let { roles } = values;
									// role = role.includes('-') ? role.split('-') : role;
									// role = Array.isArray(role) ? role.pop() : role;

									// values['role'] = role;
									addUser(values)
										.then(() => {
											notification.open({
												message: 'User added!',
												placement: 'bottomRight',
												description: 'The user was added successfully.',
												icon: <Icon type="check" style={{ color: '#108ee9' }} />,
											});
										}).catch(() => {
											notification.open({
												message: 'User already exists!',
												placement: 'bottomRight',
												description: 'The user was not added.',
												icon: <Icon type="close-circle" style={{ color: '#108ee9' }} />,
											});
										}).finally(() => {
											setIsAdding(false);
											setLoadingTable(false);
										});
								}
							});
						}}
					/>
					<Button
						icon="close"
						onClick={() => {
							setUsers(
								_users.filter(user => user.key !== record.key)
							);
							setIsAdding(false);
						}}
					/>
				</Button.Group>

			)
		}
	});

	const addUserOnTable = () => {
		const __users = [
			{
				key: _users.length,
				name: '',
				email: '',
				country: '',
				role: '',
				new: true
			},
			..._users,
		];

		setIsAdding(true);
		setUsers(__users);
	};

	const editUser = (userData, country) => {
		const _modalData = 
			{
				key: userData.sub,
				name: userData.name,
				email: userData.email,
				country,
				role: userData.role,
				userSub: userData.sub,
				adminSub: user.sub,
			};
		setAvailableRoles(userAvailableRoles());
		setModalData(_modalData);
		handleVisible(true);
	};

	return (
		<UserContext.Provider
			value={form}
		>
			<h2>Users</h2>

			<Button
				icon="plus"
				size="small"
				onClick={addUserOnTable}
				disabled={isAdding === true || !_users}
			>
				Add new user
			</Button>

			<Divider />
			{isVisible &&
        <EditModal
        	setVisible={handleVisible} 
        	visible={isVisible} 
        	modalData={modalData}
        	availableRoles={availableRoles}
        	updateUser={updateUser}
        />
			}
			<Table
				size="small"
				data-testid="users-table"
				bordered
				onRow={(record) => {
					if (!record.enabled) {
						return {
							style: disabledUserStyle
						};
					}
				}}
				columns={columns}
				// loading={!!users === false || loadingTable}
				loading={loadingTable}
				dataSource={_users}
				components={{
					body: {
						cell: TableInput
					}
				}}
			/>
		</UserContext.Provider>
	);
}


const UsersTable = Form.create()(UsersTableComponent);

export default UsersTable;
