import * as types from '../../constants/users';
import Api from '../../../services/Api/Api';

export const getUsers = () => {
	return dispatch => {
		
		dispatch({
			type: types.SET_USERS,
			users: undefined
		});

		Api.get('/user').then(({ data }) => {
			dispatch({
				type: types.SET_USERS,
				users: data.item
			});
		});
	};
};

export const addUser = (userData) => {
	return dispatch => {
		return Api.post('/user', userData).then(({data}) => {
			const user = {
				country: data.user.Attributes[0].Value,
				sub: data.user.Attributes[1].Value,
				name: data.user.Attributes[3].Value,
				email: data.user.Attributes[4].Value,
				email_verified: 'true',
				enabled: true,
				role: [...userData.role],
			};
			dispatch({
				type: types.ADD_USER,
				user
			});
		});
	};
};

export const toggleUserStatus = (userData) => {
	return dispatch => {
		return Api.post('/toggle_user_status', {username: userData.email})
			.then(() => {
				dispatch({
					type: types.TOGGLE_USER_STATUS,
					user: userData
				});
			});
	};
};

export const deleteUser = (userData) => {
	return dispatch => {
		return Api.delete(`/user?username=${userData.sub}`)
			.then(() => {
				dispatch({
					type: types.DELETE_USER,
					user: userData
				});
			});
	};
};

export const updateUser = (userData) => {
	return dispatch => {
		const data = {
			adminSub: userData.adminSub,
			userSub: userData.userSub,
			newRoles: userData.role
		};
		return Api.put('roles', data)
			.then(() => dispatch({ type: types.UPDATE_USER, user: userData }));
		// .catch(err => console.log(err));
	};
};

export const resetPassword = (userData) => {
	return dispatch => {
		return Api.patch('/user', { sub: userData.sub })
			.then(() => {
				dispatch({
					type: types.RESET_PASSWORD,
					user: userData
				});
			});
	};
};