import React from 'react';
import Page from '../../templates/Page';
import ServiceOrdering from '../../organisms/ServiceOrdering';
import { Row, Col } from 'antd';
import LoadServices from '../../organisms/LoadServices';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../../redux/actions/ServiceActions';
import './ServiceConfPage.scss';

export function ServiceConfPage() {
	return (
		<Page>
			<Row
				className="service-page"
				data-testid="serviceconf-page"
			>

				<Col
					md={12}
					className="service-page__ordering"
				>
					<h2>Service Ordering</h2>
					<ServiceOrdering />
				</Col>
				<Col
					md={12}
				>
					<h2>Load Service</h2>

					<LoadServices  />
				</Col>

			</Row>

		</Page>

	);
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(serviceActions, dispatch);

const mapStateToProps = state => ({
	services:  state.service
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceConfPage);
