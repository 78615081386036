/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Table, Button, Divider } from 'antd';
import columns from './columns';
import { useCognito } from '../../../services/Authenticator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportActions from '../../../redux/actions/ReportsActions';
import Api from '../../../services/Api/Api';
import { downloadFile as UtilDownloadfile } from '../../../services/Utils';

export const ReportContext = React.createContext();

const dateFormatOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
};

function ReportsTable() {
	const [reports, setReports] = useState([]);
	const [role, setRole] = useState();
	const { user } = useCognito();
	
	const [loadingTable, setLoadingTable] = useState(false);
	const [exportLoading, setExportLoading] = useState(false);
	const [loadContent, setLoadContent] = useState(true);

	const downloadFile = async (key) => {
		setLoadingTable(true);
		try {
			const { data } = await Api.post('/reports', {
				file: key,
			});
			UtilDownloadfile(data.link, key.split('/')[1], () => {
			  setLoadingTable(false);
			});
		} catch (error) {
			console.log(error);
			setLoadingTable(false);
		}
	};  

	// const reloadReports = () => {
	// 	setLoadContent(true);
	// };

	useEffect(() => {
		if(loadContent === true) {
			setLoadingTable(true);
			setLoadContent(false);
			const getReports = async () => {
				try {
					const { data } = await Api.get('/reports');
					const auxReports = [];
					const items = data.items;
          
					items.map((report, i) => {
						const _report = {
							...report,
							key: i,
							date: new Date(report.timestamp).toLocaleString(['eu-US'], dateFormatOptions),
							actions: (
								<Button.Group>
									<Button
										icon="download"
										loading={exportLoading}
										disabled={data.length <= 0}
										data-testid="export-csv-button"
										onClick={()=>{
											downloadFile(report.csvKey);
										}}
									>
                    CSV
									</Button>
									<Button
										icon="download"
										loading={exportLoading}
										disabled={data.length <= 0}
										data-testid="export-csv-button"
										onClick={()=>{
											setExportLoading(true);
											downloadFile(report.pdfKey);
										}}
									>
                    PDF
									</Button>
								</Button.Group>
							),
						};
						auxReports.push(_report);
					});
          
					setReports(auxReports);
				} catch (error) {
					console.log(error);
				} finally {
					setLoadingTable(false);
				}
			};
			getReports();
		}
	}, [loadContent]);

	if (user && user['cognito:groups'] && !role) {
		setRole(user['cognito:groups']);
	}

	return (
		<ReportContext.Provider
		>
			<h2>Reports</h2>

			{/* <Button
				icon="redo"
				size="small"
				onClick={reloadReports}
				disabled={loadContent === true}
			>
				Reload Reports
			</Button> */}

			<Divider />

			<Table
				size="small"
				data-testid="reports-table"
				bordered
				columns={columns}
				loading={loadingTable}
				dataSource={reports}
			/>
		</ReportContext.Provider>
	);
}

const mapStateToProps = state => ({
	reports: state.reports.reports,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(reportActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTable);
