import React from 'react';
import { Button } from 'antd';
import { EditableContext } from '../../molecules/BlacklistTable/BlacklistTable';

const { Group } = Button;

const TableOptions = ({ 
	record, 
	setEditingKey, 
	toggleEdit,
	onAdd,
	onAddCancel,
	onSave,
	onDelete,
	isAdding
}) => {

	const handleSave = (form) => {
		form.validateFields((err, values) => {
			if(!err){
				onSave(values, record);
			}
		});
	};

	const handleAdd = (form) => {
		form.validateFields((err, values) => {
			if(!err){
				onAdd(values, record);
			}
		});
	};

	const handleEdit = () => {
		setEditingKey(record.key);
	};

	const handleCancel = () => {
		setEditingKey(undefined);
	};

	const handleDelete = () => onDelete(record);

	return (
		<EditableContext.Consumer>
			{form => (
				<Group style={{padding: 0}} data-testid="table-options">

					{record.id ? (
						<>
							{toggleEdit && (
								<>
									<Button 
                                    	icon="save"
                                    	onClick={() => handleSave(form)}
                                    	data-testid="table-options-save"
									/>
									<Button 
                                    	icon="close" 
                                    	onClick={()=>handleCancel()}
                                    	data-testid="table-options-cancel"
									/>
								</>
							)}
							{!toggleEdit && (
								<>
									<Button 
                                    	disabled={isAdding}
                                    	icon="edit"
                                    	onClick={() => handleEdit()}
                                    	data-testid="table-options-edit"
									/>
									<Button 
                                    	disabled={isAdding}
                                    	icon="delete" 
                                    	onClick={()=>handleDelete()}
                                    	data-testid="table-options-delete"
									/>
								</>
							)}
						</>
					): (
						<>
							<Button 
                            	icon="check"
                            	onClick={() => handleAdd(form)}
                            	data-testid="table-options-add"
							/>

							<Button 
                            	icon="close" 
                            	onClick={()=>onAddCancel()}
                            	data-testid="table-options-add-cancel"
							/>

						</>
					)}
                   
				</Group>
			)}
		</EditableContext.Consumer>
       
	);
};

export default TableOptions;
