import React from 'react';
import Page from '../../templates/Page';
import { Row } from 'antd';
import RunVinRouter from '../../organisms/RunVinRouter';

export function VinRouterPage() {
	return (
		<Page>
			<Row
				className="service-page"
				data-testid="vin-router"
			>
				<RunVinRouter />
			</Row>
		</Page>
	);
}

export default VinRouterPage;
