import * as types from '../../constants/blacklist';

const initialState = {
    blacklists: false,
    total_items: null,
};

function BlacklistReducer(state = initialState, action) {
    
    switch (action.type) {
		case types.SET_BLACKLISTS:
            if(state.blacklists && action.refresh === false){
                return {
                    ...state,
                    blacklists: [...state.blacklists, ...action.blacklists],
                    total_items: action.total_items
                };
            }else{
                return {
                    ...state,
                    total_items: action.total_items,
                    blacklists: action.blacklists,
                };
            }


        case types.ADD_BLACKLIST:
			return {
                ...state,
                blacklists: [
                    ...state.blacklists,
                    action.blacklist
                ]
            };

        case types.REMOVE_BLACKLIST:
			return {
                ...state,
                blacklists: state.blacklists.filter(b => b.id !== action.blacklist.id)
            };

        case types.UPDATE_BLACKLIST:
            const blacklistParams = action.blacklist.blacklist;
            delete action.blacklist.blacklist;
			return {
                ...state,
                blacklists: state.blacklists.map(blacklist => {
                    if(blacklist.id === action.blacklist.id){
                        return {
                            ...action.blacklist,
                            ...blacklistParams
                        };
                    }
                    return blacklist;
                })
            };
		default:
			return state
	}
}

export default BlacklistReducer;