import * as types from '../../constants/reports';

const initialState = {
	reports: undefined
};

function ReportsReducer(state = initialState, action) {
  
	switch (action.type) {
	case types.SET_REPORTS:
		return {
			...state,
			reports: action.reports
		};

	default:
		return state;
	}
}

export default ReportsReducer;