import axios from 'axios';
import qs from 'qs';
import { clientId, cognitoWebDomain } from '../../../config';
import { CognitoAccessToken, CognitoIdToken } from 'amazon-cognito-auth-js';
import Cognito from '../../services/Authenticator/Cognito';

import { apiUrl as baseUrl } from '../../../config';

const Api = axios.create({
	baseURL: baseUrl,
	headers: {
		'Content-Type': 'application/json',
	},
});

Api.interceptors.request.use( async (config) => { 
	
	const REFRESH_TOKEN = Cognito.getSignInUserSession().getRefreshToken().getToken();
  
	const expiresIn = Cognito.getSignInUserSession().getAccessToken().payload.exp;
	const currentTimestamp = parseInt(new Date().getTime() / 1000);
    
	if(currentTimestamp > (expiresIn - 300)) {
		const data = {
			grant_type: 'refresh_token',
			client_id: clientId,
			refresh_token: REFRESH_TOKEN
		};
  
		const options = {
			method: 'POST',
			headers: {'content-type': 'application/x-www-form-urlencoded'},
			data: qs.stringify(data),
			url: `https://${cognitoWebDomain}/oauth2/token`
		};
  
		try {
			const response = await axios(options);

			Cognito.getSignInUserSession().setAccessToken(new CognitoAccessToken(response.data.access_token));
			Cognito.getSignInUserSession().setIdToken(new CognitoIdToken(response.data.id_token));
		} catch(err) {
			Cognito.signOut();
		}
	}
  
	let token = Cognito.getSignInUserSession().getIdToken().getJwtToken();
	if(!token) {
		token = Cognito.getCachedSession().getIdToken().getJwtToken();
	}

	if(token){
		config.headers.Authorization = `Bearer ${token}`;
		config.headers['x-country'] = localStorage.country;
	}
  
	return config;
});

export default Api;