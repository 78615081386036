import * as types from '../../constants/multitenancy';
import Api from '../../../services/Api/Api';

export const getCountries = () => {
	return dispatch => {
		Api.get('/country').then(({ data }) => {
			dispatch({
				type: types.SET_COUNTRY,
				countries: data,
			});
		});
	};
};
