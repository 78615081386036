import * as types from '../../constants/users';

const initialState = {
	users: undefined
};

function UsersReducer(state = initialState, action) {
  
	switch (action.type) {

	case types.SET_USERS:
		return {
			...state,
			users: action.users
		};

	case types.ADD_USER:
		return {
			users: [
				...state.users,
				action.user
			]
		};
      
	case types.TOGGLE_USER_STATUS:
		return {
			...state,
			users: [
				...state.users.map(u => {
					if(u.email === action.user.email) {
						u.enabled = !u.enabled;
					}
					return u;
				})
			]
		};
      
	case types.DELETE_USER:
		return {
			...state,
			users: [
				...state.users.filter(u => u.email !== action.user.email)
			]
		};

	case types.RESET_PASSWORD:
		return {
			...state,
			users: action.users
		};

	case types.UPDATE_USER: {
		const updatedUser = state.users.find(user => user.sub === action.user.userSub);
		updatedUser['role'] = action.user.role;
		return {
			...state,
			users: [
				...state.users
			]
		};
	}

	default:
		return state;
	}
}

export default UsersReducer;