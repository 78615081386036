import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { useCognito } from '../../../services/Authenticator';
import './NavMenu.scss';

function NavMenu({ theme, mode, location }) {
	const [role, setRole] = useState();

	const { signOut, user } = useCognito();

	useEffect(() => {
		if (user) {
			var u = user;

			if ('cognito:groups' in u) {
				setRole(u['cognito:groups'][0]);
			}
		}
	}, []);

	return (
		<Menu
			theme={theme}
			mode={mode}
			data-testid="nav-menu"
			defaultSelectedKeys={[location.pathname]}
			className="nav-menu"
		>

			{role !== 'user' && (
				<Menu.Item
					key="/services"
					className="nav-item"
				>
					<Icon type="setting" />
					<span className="nav-text">Services</span>
					<Link to="/services" data-testid="link-services" />
				</Menu.Item>
			)}

			{role !== 'user' && (
				<Menu.Item
					key="/blacklists"
					className="nav-item"
				>
					<Icon type="book" />
					<span className="nav-text">Blacklists</span>
					<Link to="/blacklists" data-testid="link-services" />
				</Menu.Item>
			)}

			<Menu.Item
				key="/vin-router"
				className="nav-item"
			>
				<Icon type="ordered-list" />
				<span className="nav-text">Search Eurocode</span>
				<Link to="/vin-router" data-testid="link-services" />
			</Menu.Item>

			{role !== 'user' && (
				<Menu.Item
					key="/users"
					className="nav-item"
				>
					<Icon type="idcard" />
					<span className="nav-text">Users</span>
					<Link to="/users" data-testid="link-users" />
				</Menu.Item>
			)}

			{role !== 'user' && (
				<Menu.Item
					key="/reports"
					className="nav-item"
				>
					<Icon type="snippets" />
					<span className="nav-text">Reports</span>
					<Link to="/reports" data-testid="link-reports" />
				</Menu.Item>
			)}

			<Menu.Item key="3" onClick={() => signOut()}>
				<Icon type="logout" />
				<span className="nav-text">Logout</span>
			</Menu.Item>
		</Menu>
	);
}

NavMenu.propTypes = {
	theme: PropTypes.string,
	mode: PropTypes.string,
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}),
};

NavMenu.defaultProps = {
	theme: 'dark',
	mode: 'inline'
};

export default withRouter(NavMenu);
