import * as types from '../../constants/order';
import * as stypes from '../../constants/services';
import Api from '../../../services/Api/Api';

export const saveOrder = ( services ) => {
	let data = {};
	let formatted_services = services.map(service => service.id);
	data.services = formatted_services;

	return dispatch => {
		return Api.put('/services', data).then(({ data })=>{

			if(data.services){
				dispatch({
					type: types.SET_ORDER,
					order: services
				});
				dispatch({
					type: stypes.LOAD_SERVICES,
					services
				});
			}else{
				return data.message;
			}

		});
	};
};

export const loadOrder = ( order ) => {

	return (dispatch, getState) => {

		const { ordering } = getState();

		if(ordering.length !== order.length){
			return;
		}
		dispatch({
			type: types.SET_ORDER,
			order
		});
	};
};