import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import './Raw.scss';

function Raw({ children }) {
   
	const { Content } = Layout;
    
	return (
		<Layout data-testid="raw-template" className="raw-template">
			<Content className="raw-template_content">
				{children}
			</Content>
		</Layout>
	);
}

Raw.propTypes  = {
	children: PropTypes.object,
};

export default Raw;


