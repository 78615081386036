import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Service from '../../atoms/Service';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import * as orderingActions from '../../../redux/actions/OrderingActions';
import * as serviceActions from '../../../redux/actions/ServiceActions';
import * as vinRouterActions from '../../../redux/actions/VinrouterActions';
import { reorder } from '../../../services/DragService/DragService';
import { Modal, Icon, notification } from 'antd';
import './OrderingList.scss';

const { confirm } = Modal;

const confirmDeleteService = ({onOk}) => {
	confirm({
		title: 'Do you want to delete this service?',
		content: 'When clicking the YES button, this service will be deleted',
		okText: 'Yes',
		okType: 'danger',
		onOk
	});
};

export function OrderingList({ ordering, deleteService,  loadOrder, loadOrders }) {

	const remove = (id) => {
		confirmDeleteService({
			id,
			onOk: () => {
				deleteService(id).then(() => {
					loadOrders();
					const notificationData = {
						message:'Service deleted!',
						description:'The service was deleted successfully.',
						icon: <Icon type={'smile'} style={{ color: '#108ee9' }} />,
						config: {
							placement: 'bottomRight',
							bottom: 10
						}
					};
					notification.open(notificationData);
				});
			}
		});
	};

	const onDragEnd = ( result )=>{
		if(!result.destination) {
			return false; 
		}
		const items = reorder(
			ordering, 
			result.source.index, 
			result.destination.index
		);

		loadOrder(items);

		return true;
	};

	return (
		<DragDropContext
			onDragEnd={onDragEnd}
		>
			<Droppable droppableId={'ordering-list-1'}>
				{(provided, snapshot) => (
					<ul 
						className={`ordering-list ${snapshot.isDraggingOver && 'is-dragging'}`}
						data-testid="ordering-list"
						ref={provided.innerRef} 
						{...provided.droppableProps}
					>
						{ordering.map(({ id, name }, i) => (
							<Draggable 
								key={i}
								draggableId={id}
								index={i}
							>
								{(provided, snapshot) => (
									<Service 
										id={id}
										isDragging={snapshot.isDragging}
										index={i}
										draggableProps={provided.draggableProps}
										dragHandleProps={provided.dragHandleProps}
										reference={provided.innerRef}
										name={name}
										remove={remove}
									/>
								)}
							</Draggable>
						))}
					</ul>
				)}
			</Droppable>
		</DragDropContext>
		
	);
}

OrderingList.propTypes = {
	ordering: PropTypes.array,
	deleteService: PropTypes.func,
	loadOrder: PropTypes.func 
};


const mapStateToProps = state => ({
	services: state.service,
	ordering: state.ordering
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({...orderingActions, ...serviceActions, ...vinRouterActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderingList);