import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tag, Button } from 'antd';
import './Service.scss';

const Service = ({ 
	id, 
	isDragging,
	name,
	reference, 
	index, 
	remove, 
	dragHandleProps, 
	draggableProps 
}) => {

	return (
		<li 
			data-testid="service-item"
			ref={reference} 
			className={`service-item ${isDragging &&'is-dragging'}`}
			{...draggableProps}
			{...dragHandleProps}
		>
			<span className="">
				<span>
					<Tag color="red">{index}</Tag>
				</span>
				<span>
					<Button type="dashed" shape="circle" icon="delete" onClick={() => remove(id)} />
				</span>
			</span>
			
			<span className="service-name">{name}</span>
			<Icon type="drag"></Icon>
		</li>
	);
};

Service.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string, 
	index: PropTypes.number, 
	isDragging: PropTypes.bool,
	reference: PropTypes.func, 
	remove: PropTypes.func, 
	dragHandleProps: PropTypes.object, 
	draggableProps: PropTypes.object 
};

export default Service;