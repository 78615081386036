/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Icon, Input } from 'antd';


let inputRefs = {};
 
export const getColumnSearchProps = (dataIndex, handleSearch, handleReset, data) => ({
	filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
			<Input
				placeholder={`Search ${dataIndex}`}
				data-testid="filter-dropdown-input"
				ref = {(node)=>{
					inputRefs[dataIndex] = node;
					return node;
				}}  
				value={selectedKeys[0]}
				onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch({
					key: dataIndex,
					value: selectedKeys[0]
				}, clearFilters, data)}
				style={{ width: 188, marginBottom: 8, display: 'block' }}
			/>
			<Button
				type="primary"
				data-testid="filter-dropdown-search"
				onClick={() => handleSearch({
					key: dataIndex,
					value: selectedKeys[0]
				}, confirm, data)}
				icon="search"
				size="small"
				style={{ width: 90, marginRight: 8 }}
			>
                Search
			</Button>
			<Button 
				onClick={() => {
					handleReset(clearFilters);
				}} 
				size="small" style={{ width: 90 }}
				data-testid="filter-dropdown-reset"
			>
                Reset
			</Button>
		</div>
	),
	filterIcon: filtered => (
		<Icon 
			type="search" 
			data-testid="filter-icon"
			style={{ color: filtered ? '#1890ff' : undefined }} 
		/>
	),
	onFilterDropdownVisibleChange: visible => {
		if (visible) {
			setTimeout(() => inputRefs[dataIndex].select());
		}
	},
});

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		editable: true,
		align: 'center',
		width: 160
	},
	{
		title: 'Service',
		dataIndex: 'service',
		key: 'service',
		editable: true,
		isSelect: true,
		required: true,
		align: 'center',
		width: 160,
		onFilter: (value, record) => {
			if(record._service)
				return record._service.id === value;
		},
	},
	{
		title: 'VIN',
		dataIndex: 'vin',
		key: 'vin',
		align: 'center',
		width: 160,
		editable: true,
		isInfo: true,
	},
	{
		title: 'Make',
		dataIndex: 'make',
		key: 'make',
		align: 'center',
		width: 160,
		editable: true,
	},
	{
		title: 'Model',
		dataIndex: 'model',
		key: 'model',
		align: 'center',
		width: 160,
		editable: true,
	},
	{
		title: 'Year',
		dataIndex: 'year',
		key: 'year',
		align: 'center',
		width: 160,
		editable: true,
	},
	{
		title: 'Origin',
		dataIndex: 'origin',
		key: 'origin',
		align: 'center',
		width: 160,
		editable: true,
	},
	{
		title: 'Hole',
		dataIndex: 'hole',
		key: 'hole',
		align: 'center',
		width:160,
		editable: true,
	},
	{
		title: 'Trim',
		dataIndex: 'trim',
		key: 'trim',
		align: 'center',
		editable: true,
		width: 170
	},
	{
		title: 'Actions',
		dataIndex: 'actions',
		key: 'actions',
		isOptions: true,
		width: 80,
		fixed: 'right',
		align: 'center',
	}
];

export default columns;